import { Dayjs } from 'dayjs'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters('app', ['isMobile']),
  },
})
export class BaseChart extends Vue {
  @Prop({ type: String, default: '' }) startDate!: string;
  @Prop({ type: String, default: '' }) endDate!: string;
  isMobile!: boolean;
  chartOptions: Record<string, any> = {}
  series = []
  display = false

  generateDayRange (start: Dayjs, end: Dayjs) {
    const range: Dayjs[] = [start]
    let newDate = start

    while (newDate.isBefore(end)) {
      newDate = newDate.add(1, 'day')
      range.push(newDate)
    }

    return range
  }

  get width () {
    const { $vuetify, isMobile } = this

    return isMobile ? $vuetify.breakpoint.width - 50 : $vuetify.breakpoint.width - 725
  }

  get height () {
    const { isMobile } = this
    return isMobile ? 300 : 600
  }

  get small () {
    const { $vuetify, isMobile } = this

    return isMobile ? $vuetify.breakpoint.width - 25 : $vuetify.breakpoint.width - 10
  }

  get datesChange () {
    const { startDate, endDate } = this

    return {
      startDate,
      endDate,
    }
  }

  getBarColor (percentage) {
    const colors = [
      {
        threshold: 20,
        color: '#F03738', // red
      },
      {
        threshold: 40,
        color: '#FF9E2C', // orange
      },
      {
        threshold: 60,
        color: '#FAD901', // yellow
      },
      {
        threshold: 80,
        color: '#13CE66', // green
      },
      {
        threshold: 101,
        color: '#6185DB', // blue
      },
    ]

    let returnColor = colors[0].color
    if (percentage >= 100) {
      returnColor = colors[colors.length - 1].color
    }

    if (!percentage) return returnColor

    colors
      .sort((prev, next) => {
        return next.threshold - prev.threshold
      })
      .forEach(color => {
        if (percentage < color.threshold) {
          returnColor = color.color
        }
      })

    return returnColor
  }
}
