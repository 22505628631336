
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import { channelColors } from '@/utils/channelColors'

@Component({})
  export default class LeadsByChannelPie extends BaseChart {
  // Methods
  @Prop({ type: String, default: null }) title
  @Prop({ type: Boolean, default: true }) showTitle
  @Prop() leads: Record<string, any>
  channels = []
  channelColors = channelColors

  getCountByChannel (channel) {
    if (!this.leads?.records) return 0
    return this.leads.records.filter(lead => lead.deals?.[0]?.channel?.name === channel).length
  }

  getPipelinesByChannel (channel) {
    let pipelines = []
    const byChannel = this.leads.records.filter(lead => lead.deals?.[0]?.channel?.name === channel)
    byChannel.forEach(lead => {
      if (!pipelines.map(pipeline => pipeline.name).includes(lead.pipeline.name)) {
        pipelines.push(lead.pipeline)
      }
    })
    pipelines = [...new Set(pipelines)]
    const leadsByPipeline = pipelines.map(pipeline => {
      return {
        color: pipeline.color,
        name: pipeline.name,
        description: pipeline.description,
        leads: byChannel.filter(lead => lead.pipeline.name === pipeline.name),
      }
    })
    return leadsByPipeline.sort((prev, next) => next.leads.length - prev.leads.length)
  }

  getData () {
    if (!this.leads) return
    const leads = this.leads?.records
    if (!leads) return

    let channels = []
    leads.forEach(lead => {
      if (!lead.deals[0]) return
      if (!channels.map(channel => channel.name).includes(lead.deals[0].channel.name)) {
        channels.push(lead.deals[0].channel)
      }
    })
    channels = channels.sort((prev, next) => {
      return this.getCountByChannel(next.name) - this.getCountByChannel(prev.name)
    })
    this.channels = channels
    this.series = channels.map(channel => {
      return leads.filter(lead => {
        return lead.deals?.[0]?.channel?.name === channel.name
      }).length
    })

    this.chartOptions = {
      colors: channels.map(channel => channelColors[channel.name]),
      chart: {
        type: 'pie',
      },
      labels: channels.map(channel => channel.description),
      title: {
        text: this.showTitle ? (this.title ? '' : 'Por canal') : undefined,
        style: {
          fontFamily: 'Poppins',
        },
      },
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'center',
        fontFamily: 'Poppins',
        fontSize: '14px',
      },
    }
  }

  // Getters

  get leadsTotal () {
    if (!this.leads?.records) return 0
    return this.leads?.records?.length
  }

  // Watchers
  @Watch('leads', { immediate: true, deep: true })
  onDatesChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
