
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import dayjs from 'dayjs'

@Component({})
  export default class LeadsByPipeline extends BaseChart {
  @Prop() leads: Record<string, any>
  @Prop() prevMonthLeads: Record<string, any>
  @Prop({ type: String, default: null }) title
  @Prop() dates
  pipelines = []

  toggleSerie (serie) {
    const notHide = [serie, serie + ' (mes anterior)']
    this.pipelines.forEach(pipeline => {
      if (notHide.includes(pipeline.description)) {
        this.$refs.chart.showSeries(pipeline.description)
        this.$refs.chart.showSeries(pipeline.description + ' (mes anterior)')
      } else {
        this.$refs.chart.hideSeries(pipeline.description)
        this.$refs.chart.hideSeries(pipeline.description + ' (mes anterior)')
      }
    })
  }

  getData () {
    if (!this.leads || !this.prevMonthLeads) return

    const leads = this.leads.records
    const prevMonthLeads = this.prevMonthLeads.records

    if (!leads) return
    const categories = this.generateDayRange(
      dayjs(this.dates.start),
      dayjs(this.dates.end)
    )
    const prevMonthRange = this.generateDayRange(
      dayjs(this.dates.start).subtract(1, 'month').startOf('month'),
      dayjs(this.dates.start).subtract(1, 'month').endOf('month')
    ).slice(0,
      this.generateDayRange(dayjs(this.dates.start), dayjs(this.dates.start).endOf('month')).length - 1
    )

    const allLeads = [...leads, ...prevMonthLeads]
    const pipelines = []
    allLeads.forEach(lead => {
      if (!pipelines.map(pipeline => pipeline.name).includes(lead.pipeline.name)) {
        pipelines.push(lead.pipeline)
      }
    })
    this.pipelines = pipelines

    const currentMonth = pipelines.map(pipeline => {
      const data = []
      categories.forEach(category => {
        const filteredLeads = leads.filter(lead => {
          return dayjs(lead.created_at).isSame(dayjs(category), 'day') &&
            lead.pipeline.name === pipeline.name
        })
        data.push(filteredLeads.length)
      })
      return {
        name: pipeline.description,
        data,
        color: pipeline.color.split(' ')[0],
        type: 'line',
      }
    })

    const prevMonth = pipelines.map(pipeline => {
      const data = []
      prevMonthRange.forEach(category => {
        const filteredLeads = prevMonthLeads.filter(lead => {
          return dayjs(lead.created_at).isSame(dayjs(category), 'day') &&
            lead.pipeline.name === pipeline.name
        })
        data.push(filteredLeads.length)
      })
      return {
        name: `${pipeline.description} (mes anterior)`,
        data,
        color: pipeline.color.split(' ')[0] + '4C',
        type: 'line',
      }
    })

    this.series = [...currentMonth, ...prevMonth]

    this.series.push({
      name: 'Total',
      data: categories.map(category => {
        return leads.filter(lead => {
          return dayjs(lead.created_at).isSame(dayjs(category), 'day')
        }).length
      }),
      type: 'area',
      color: '#DCFCE7',
    })

    let maxSum = this.series
      .map(serie => serie.data)
      .flat()
      .sort((a, b) => b - a)[0]

    if (maxSum % 2 !== 0) maxSum++

    this.chartOptions = {
      chart: {
        stacked: false,
      },
      stroke: {
        width: 3,
        curve: 'smooth',
      },
      xaxis: {
        categories,
        type: 'category',
        labels: {
          formatter (val) {
            return dayjs(val).format('DD/MM')
          },
        },
      },
      title: {
        text: this.title ? '' : 'Por negocio',
        style: {
          fontFamily: 'Poppins',
        },
      },
      yaxis: {
        min: 0,
        max: maxSum,
        forceNiceScale: true,
      },
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'center',
        fontFamily: 'Poppins',
        fontSize: '14px',
      },
    }
  }

  // Getters
  get withOutLeads () {
    return !this.leads?.records?.length
  }

  // Watchers
  @Watch('leads', { immediate: true, deep: true })
  @Watch('dates', { immediate: true, deep: true })
  onDatesChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
